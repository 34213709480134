import React from 'react';
import Chat from './components/Chat/Chat';
import useSocket from './Hooks/useSocket';
import './_App.scss';
export let socket;

function App() {
  [socket] = useSocket("https://plugtochat.com:8000");
  return (
    <Chat />
  );
}

export default App;
